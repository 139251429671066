import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Footer = () => {

  return (
    <>
      <ContainerDiv>
        <LeftPane>Harvard Garden Education 2020</LeftPane>
        <RightMenuContainer>
          <MenuBar>
            <MenuItem><Link to="/about">ABOUT US</Link></MenuItem>
            <MenuItem><Link to="/courses">COURSES</Link></MenuItem>
            <MenuItem><Link to="/career">CAREER</Link></MenuItem>
            <MenuItem><Link to="/contact">CONTACT US</Link></MenuItem>
          </MenuBar>
        </RightMenuContainer>
      </ContainerDiv>
    </>
  )
}

export default Footer

const ContainerDiv = styled.div`
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: #65977C;
    // border: 1px solid blue;
    align-items: center;
    display:flex;
    justify-content:space-between;
`

const LeftPane = styled.div`
    margin-left: 5em;
    // border: 1px solid blue;
    display: inline;
    font-family: "Inter", sans serif;
    color: white;
`
const RightMenuContainer = styled.div`
    display: flex;
    // border: 1px solid red;

`

const MenuBar = styled.div`
    margin-right: 3em;
    display:flex;
    justify-content:space-between;
`

const MenuItem = styled.p`
    margin: 2em;
    font-size:0.9em;
    font-family: 'Inter';
    font-weight: 600;
    color: white;
    a {
        text-decoration: none;
        color: white;
    }
    a:visited {
        color: white;
    }
`
