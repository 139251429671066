import React from 'react'
import styled from 'styled-components'

const CenterLogo = styled.img`
    display: block;
    margin: auto;
    margin-top: 30vh;
    width: 10%;
    // border: 1px solid red;

`

const Logo = ( { url } ) => {

  return (
    <CenterLogo src={url}>
    </CenterLogo>
  )
}

export default Logo