import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'
import './index.css'
import App from './App'
import About from './pages/About'
import Courses from './pages/Courses'
import Course from './pages/Course'
import Contact from './pages/Contact'
import ComingSoon from './pages/ComingSoon'
import TimeTable from './pages/TimeTable'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}/>
      <Route path="/about" element={<About />}/>
      <Route path="/contact" element={<Contact />}/>
      <Route path="/timetable" element={<TimeTable />}/>
      <Route path="/career" element={<ComingSoon />}/>
      <Route path="/courses" element={<Courses />}/>
      <Route path="/courses/chinese-is-fun" element={<Course type={'chineseIsFun'}/>} />
      <Route path="/courses/fundamental-chinese" element={<Course type={'fundamentalChinese'} />} />
      <Route path="/courses/intermediate-chinese" element={<Course type={'intermediateChinese'} />} />
      <Route path="/courses/advanced-chinese" element={<Course type={'advancedChinese'} />} />
      <Route path="/courses/singapore-math" element={<Course type={'singaporeMath'} />} />
      <Route path="/courses/afterschool-program" element={<Course type={'afterschoolProgram'} />} />
      <Route path="/courses/summer-camp" element={<Course type={'summerCamp'} />} />
      <Route path="/courses/english-french" element={<Course type={'englishFrench'} />} />
      <Route path="/courses/japanese" element={<Course type={'japanese'} />} />
      {/*

        <Route path="/timetable" element={<Timetable />}/>
        <Route path="/career" element={<Career />}/>
        <Route path="/contact" element={<Contact />}/>*/}
    </Routes>
  </BrowserRouter>
)

