import React from 'react'
import styled from 'styled-components'
import 'antd/dist/antd.css'
import Header from '../components/about/Header'
import Footer from '../components/about/Footer'
import CourseTiles from '../components/courses/CourseTiles'



const Courses = () => {

  return (
    <>
      <MainContentWrapper>
        <BackgroundBanner>
          <Header />
          <CourseTiles />
          <Footer />
        </BackgroundBanner>
      </MainContentWrapper>

    </>
  )
}

export default Courses


const MainContentWrapper = styled.div`
`

const BackgroundBanner = styled.div`
    background-image: url("https://res.cloudinary.com/dl4murstw/image/upload/v1664055202/Vector_3_iqazut.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    min-height:100vh; 
    display:flex; 
    flex-direction:column; 
    justify-content:space-between;
`
