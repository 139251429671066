import React from 'react'
import styled from 'styled-components'


const QuoteContainer = styled.div`
    margin: auto;
    position: relative;
`

const Quote = styled.div`
    font-family: "Arima Madurai", sans-serif;
    width: 50%;
    margin: auto;
    font-size: 1.5em;
    margin-top: 10em;
    margin-bottom: 10em;
    text-align: center;
`

const CenterQuote = ( { text } ) => {
  return (
    <>

      <QuoteContainer>
        <FloatingStarLeft><img src="https://res.cloudinary.com/dl4murstw/image/upload/v1663267488/Stars2_u0hyqm.png" height="300"/></FloatingStarLeft>
        <Quote>{ text }</Quote>
        <FloatingStarRight><img src="https://res.cloudinary.com/dl4murstw/image/upload/v1663267470/Stars_udy4pn.png" height="300"/></FloatingStarRight>
      </QuoteContainer>

    </>


  )
}

export default CenterQuote

const FloatingStarRight = styled.div`
    position: absolute;
    right: 0;
    top: 3em;
    z-index: 1;
`

const FloatingStarLeft = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
`