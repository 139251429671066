import styled from 'styled-components'



export const Button = styled.button`
    border: 1px solid #000000;
    border-radius: 30px;
    width: 100%;
    background-color: transparent;
    height: 3em;
    font-size: 0.9em;
    font-family: "Inter", sans-serif;
    font-weight: bold;
    color: black;
    :hover {
        background: #D4DB7D;
        border-radius: 30px;
        border: none;
        width: 100%;
        color: #FFFFFF;
        cursor: pointer;
    }
`
