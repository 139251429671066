import React from 'react'
import styled from 'styled-components'
import 'antd/dist/antd.css'
import Menu from '../components/about/Header'
import Footer from '../components/homepage/Footer'
import { courseHelper } from './courseHelper'
import CourseHeader from '../components/courses/CourseHeader'
import CourseBody from '../components/courses/CourseBody'
// import { Divider } from 'antd'

// const chineseIsFunDescription = 'A beginner Chinese class for nursery and elementary school students, with the aims of exposing them to all aspects of Chinese.'


const Course = ( { type } ) => {
  const courseInfo = courseHelper[type]
  return (
    <>
      <MainContentWrapper>
        <BackgroundBanner>
          <Menu />
          <CourseHeader
            logo={courseInfo.logo}
            title={courseInfo.title}
            audience={courseInfo.audienceText}
            description={courseInfo.description}
          />
          <CourseBody type={ type }/>
          <Footer />
        </BackgroundBanner>
      </MainContentWrapper>

    </>
  )
}

export default Course

const MainContentWrapper = styled.div`

`

const BackgroundBanner = styled.div`
    background-image: url("https://res.cloudinary.com/dl4murstw/image/upload/v1664055202/Vector_3_iqazut.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    
`
// const CourseContainer = styled.div`
//     width: 80%;
//     margin: auto;
// `

// const CourseTitle = styled.h1`
//     font-family: "Arima Madurai", sans-serif;
//     font-size: 3em;
//     margin: 0;
//     color: #66997E;
//     margin-top: 3em;
//     margin-bottom: 0;
// `

// const RowContainer = styled.div`
//     display: flex;
//     justify-content: space-between;
// `
