export const courseHelper = {
  chineseIsFun: {
    logo: 'https://res.cloudinary.com/dl4murstw/image/upload/v1665255475/girl_c6und4.png',
    title: 'Chinese Is Fun!',
    audienceText: 'For: Toddlers / Absolute Beginners [4-8years old]',
    description: 'A beginner chinese class for nursery and elementary school students, with the aims of exposing them to all aspects of Chinese. \n\n 我们针对不同年龄的孩子用不同的教材',
    quote: '对初学者此，低龄儿童我们用自编教材：趣味识字，有阅读，认字识字教学，笔顺笔划教学，学生练习：描一描，写一写。还有一个手工活动：剪一剪，贴一贴等。',
  },
  fundamentalChinese: {
    logo: 'https://res.cloudinary.com/dl4murstw/image/upload/v1665255509/chat_dgrs4z.png',
    title: 'Fundamental Chinese',
    audienceText: 'For: Students [6-12years old]',
    description: '我们针对不同年龄的孩子用不同的教材.'
  },
  intermediateChinese: {
    logo: 'https://res.cloudinary.com/dl4murstw/image/upload/v1665255505/language-exchange_sxf3mb.png',
    title: 'Intermediate Chinese',
    audienceText: 'For: High School Students [8-14years old]',
    description: 'An intermediate school students who want to take their language mastery to the next level.'
  },
  advancedChinese: {
    logo: 'https://res.cloudinary.com/dl4murstw/image/upload/v1665255509/chat_dgrs4z.png',
    title: 'Advanced Chinese',
    audienceText: 'For: Students [14 - 18years old]',
    description: 'An advanced class for students who wants to take the IB/AP / HSK.  Perfect for those who needs help for exam training and writing pratices.We got you. '
  },
  singaporeMath: {
    logo: 'https://res.cloudinary.com/dl4murstw/image/upload/v1665255519/math_lndqau.png',
    title: 'Singapore Mathematics',
    audienceText: 'For: Students [8 - 14 years old]',
    description: 'A special class for those who wants to challenge themselves'
  },
  afterschoolProgram: {
    logo: 'https://res.cloudinary.com/dl4murstw/image/upload/v1663213051/bus_1_mjgula.png',
    title: 'After School Programs',
    audienceText: 'For: Students [5 - 12 years old]',
    description: 'A high quality after-school program in a Chinese speaking environtment.'
  },
  summerCamp: {
    logo: 'https://res.cloudinary.com/dl4murstw/image/upload/v1665255526/camping_v7yezs.png',
    title: 'Summer Camp',
    audienceText: 'For: Kids [5-14 years old]',
    description: 'What’s a summer without summer camp?'
  },
  englishFrench: {
    logo: 'https://res.cloudinary.com/dl4murstw/image/upload/v1670824274/HGE%20Course%20Assets/lettering_ognbun.png',
    title: 'English / French CLasses',
    audienceText: 'For: Kids [5-14 years old]',
    description: ''
  },
  japanese: {
    logo: 'https://res.cloudinary.com/dl4murstw/image/upload/v1674574025/HGE%20Course%20Assets/159266_97a8bd7b02f54abaa617905e502d1c90_mv2_mfz8sn.webp',
    title: 'Japanese classes',
    audienceText: 'For: Kids [5-14 years old]',
    description: ''
  }
}
