import React from 'react'
// import styled from 'styled-components'
import CenterPara from '../styled/centerPara'
import CenterImg from '../styled/centerImg'
import CenterNormalText from '../styled/centerNormalText'
import Divider from '../styled/divider'
import { Button } from '../styled/Button'
// import HorizontalQuoteImg from '../styled/HorizontalQuoteImg'

const FocusSection = ( { type } ) => {

  switch (type) {
  case 'chineseIsFun':
    return (
      <>
        <CenterPara underline mt={15} mb={10} text={'What We Focus On'}/>
        <CenterPara text={'✩趣味识字✩'}/>
        <CenterImg mt={0} mb={5} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1692782756/HGE%20Course%20Assets/xhg5_rbara3.png'}/>
        <CenterPara mt={10} text={'✩阅读✩'}/>
        <CenterImg mt={0} mb={5} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1692782756/HGE%20Course%20Assets/xhg1_ru4pge.png'}/>
        <CenterPara mt={10} text={'✩笔顺笔划教学✩'}/>
        <CenterImg mt={0} mb={5} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1692782756/HGE%20Course%20Assets/xhg3_cpghlk.png'}/>
        <CenterPara mt={10} text={'✩学生练习：描一描，写一写✩'}/>
        <CenterImg mt={0} mb={5} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1692782756/HGE%20Course%20Assets/xhg4_yfjicw.png'}/>
        <CenterImg mt={0} mb={5} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1692782756/HGE%20Course%20Assets/xhg2_th8tde.png'}/>
        <Divider mt={10} mb={4}/>
      </>
    )
  case 'fundamentalChinese':
    return (
      <>
        <CenterPara underline mt={15} mb={10} text={'What We Focus On'}/>
        <CenterPara text={'✩Fun Words Recognition✩'}/>
        <CenterImg mt={0} mb={5} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670752431/HGE%20Course%20Assets/Screenshot_2022-09-25_at_7.58.53_AM_ospmed.png'}/>
        <CenterImg mt={0} mb={5} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670752433/HGE%20Course%20Assets/Screenshot_2022-09-25_at_7.58.36_AM_prhrwc.png'}/>
        <CenterImg mt={0} mb={5} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670752456/HGE%20Course%20Assets/Screenshot_2022-09-25_at_7.59_1_lb6v9x.png'}/>
        <CenterPara text={'✩Hanyupinyin✩'}/>
        <CenterImg mt={0} mb={5} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670752580/HGE%20Course%20Assets/chinese_pinyin_chart_chalkboard_1_zmyd3q.png'}/>
        <CenterPara text={'✩Igniting Student’s Love for Chinese✩'}/>
        <CenterImg mt={0} mb={5} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670752662/HGE%20Course%20Assets/DSC_0124_f3l7na.jpg'}/>
      </>
    )
  case 'intermediateChinese':
    return (
      <>
        <CenterPara underline mt={15} mb={10} text={'What We Focus On'}/>
        <CenterPara text={'✩Extensive Reading, Writing, Vocabulary Building✩'}/>
        <CenterImg mt={0} mb={5} small url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670752837/HGE%20Course%20Assets/4abc56689789cd727a42b9e8b0e0d5b9_etgcpw.jpg'}/>
        <CenterImg mt={0} mb={5} small url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670753136/HGE%20Course%20Assets/f39d542c0bba1a8ce164b0edcc3bbb39_iulmfv.jpg'}/>
        <CenterImg mt={0} mb={5} small url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670753156/HGE%20Course%20Assets/015552d7767f06fd75a2a248d5e19a53_fud44v.jpg'}/>
        <CenterImg mt={0} mb={5} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670753173/HGE%20Course%20Assets/jpg_j5yclr.jpg'}/>
        <CenterPara text={'✩Exposure to Chinese Culture✩'}/>
        <CenterImg mt={0} mb={5} small url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670753318/HGE%20Course%20Assets/53d245b5c0e8f781e1aecb79ae3a3d84_rmptik.jpg'}/>
        <CenterImg mt={0} mb={5} small url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670753329/HGE%20Course%20Assets/d67642ed66d54d74be67a5ac68c88d89_cebr4s.jpg'}/>
        <CenterImg mt={0} mb={5} small url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670753336/HGE%20Course%20Assets/1df620ddd93b4827831ee688caff1806_qwgmbr.jpg'}/>
        <CenterPara text={'✩Basic Characters Recognition And Practice✩'}/>
        <CenterPara text={'✩Character Development✩'}/>
        <CenterPara mb={10} text={'✩Analytical Thinking✩'}/>
        <Divider mt={5} mb={5}/>
      </>
    )
  case 'advancedChinese':
    return (
      <>
        <CenterPara underline text={'What We Focus On'}/>
        <CenterPara text={'✩Most Effective Writing practices, and Exam Techniques✩'}/>
        <CenterImg mt={0} mb={5} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670768963/HGE%20Course%20Assets/apchinese-1024x809_kcsrhq.png'}/>

      </>
    )
  case 'singaporeMath':
    return (
      <>
        <CenterPara text={'What We Focus On'}/>
      </>
    )
  case 'afterschoolProgram':
    return (
      <>
        <CenterPara underline text={'How we run our after school’s programs:'}/>
        <CenterNormalText text={'1 - Our dedicated teachers will pick your child up from the elementary school. '} />
        <CenterNormalText text={'2 - We offer high quality tuition classes everyday.'} />
        <CenterImg mt={0} mb={5} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670823190/HGE%20Course%20Assets/Screenshot_2022-09-25_at_9.11_1_tygnmp.png'}/>
        <CenterNormalText text={'3 - Flexible pick up from 5pm to 6pm.'} />
        <CenterPara underline mt={5} text={'The Cost:'}/>
        <CenterImg mt={0} mb={5} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670823192/HGE%20Course%20Assets/Frame_52_lgmb3m.png'}/>
        <div style={{ width:'50%', margin:'auto', textAlign: 'center' }}><Button> Contact Us</Button></div>
        <Divider mt={15} mb={15}/>
      </>
    )
  case 'summerCamp':
    break
  }
}

export default FocusSection