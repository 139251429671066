import React from 'react'
import styled from 'styled-components'


const Title = styled.h1`
    font-family: "Arima Madurai", sans-serif;
    font-size: 2.5em;
    text-align: center;
    margin: 3em;
    margin-bottom: 1em;
    // border: 1px solid red;

`
const RecentActivitiesContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    // border: 1px solid green;
    width: 70%;
    margin: auto;
    padding-bottom: 5em;
`

const PhotoGridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    // border: 1px solid red;
`

const PhotoColumn = styled.div`
    flex: 20%;
    max-width: 50%;
    padding: 10px 16px;
`

const Img = styled.img`
    margin-top: 16px;
    vertical-align: middle;
    width: 100%;
`

const RecentActivities = () => {

  return (
    <>
      <RecentActivitiesContainer>
        <Title>Recent Activities</Title>
        <PhotoGridContainer>
          <PhotoColumn>
            <Img src='https://res.cloudinary.com/dl4murstw/image/upload/v1663295480/NFT_Card_acb8sy.png?auto=compress&cs=tinysrgb&dpr=2&h=375&w=630' />

            <Img src="https://res.cloudinary.com/dl4murstw/image/upload/v1663295411/IMG_5556_gi4zeb.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />

            <Img src="https://res.cloudinary.com/dl4murstw/image/upload/v1663295561/IMG_5491_cnyqzj.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />

            <Img src="https://res.cloudinary.com/dl4murstw/image/upload/v1663295411/IMG_5467_egjx24.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />

            <Img src="https://res.cloudinary.com/dl4murstw/image/upload/v1663295716/IMG_E8988_hllgfr.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
          </PhotoColumn>

          <PhotoColumn>
            <Img src='https://res.cloudinary.com/dl4murstw/image/upload/v1663295415/IMG_E8916_lgwbwm.jpg?auto=compress&cs=tinysrgb&dpr=2&h=375&w=630' />

            <Img src="https://res.cloudinary.com/dl4murstw/image/upload/v1663295416/IMG_E9521_zmlyhh.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />

            <Img src="https://res.cloudinary.com/dl4murstw/image/upload/v1663295413/IMG_8946_brmbiv.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />

            <Img src="https://res.cloudinary.com/dl4murstw/image/upload/v1663295412/IMG_5732_ifgaga.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />

          </PhotoColumn>
        </PhotoGridContainer>
      </RecentActivitiesContainer>
    </>

  )
}

export default RecentActivities
