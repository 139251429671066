import React from 'react'
import CenterImg from '../styled/centerImg'
import CenterPara from '../styled/centerPara'
import CenterQuote from '../styled/centerQuote'
import FocusSection from './FocusSection'
import LargeGreenText from '../styled/largeGreenText'
import HorizontalQuoteImg from '../styled/HorizontalQuoteImg'
import { courseHelper } from '../../pages/courseHelper'
import { Button } from '../styled/Button'
import Divider from '../styled/divider'



const CourseBody = ( { type } ) => {
  switch (type) {
  case 'chineseIsFun':
    return (
      <>
        <CenterImg url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670747541/HGE%20Course%20Assets/42aba74456f5006dff31571fe7d89a38_oyvawa.jpg'}/>
        <CenterImg url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670747932/HGE%20Course%20Assets/ee9471ac0856e4986c18299f977946a5_l5utdt.jpg'}/>
        <CenterQuote text={courseHelper.chineseIsFun.quote}/>
        <FocusSection type={ type }/>
      </>
    )
  case 'fundamentalChinese':
    return (
      <>
        <LargeGreenText text={'我们的教材：中国的人教版，教育部针对海外学子的教材，暨南大学出版社出版的中文教材，以及新加坡教材等等。'}/>
        <CenterPara text={'我们因材施教，量体裁衣。根据程度和孩子的接受能力选取不同的教材。'}/>
        <FocusSection type={ type }/>
      </>
    )
  case 'intermediateChinese':
    return (
      <>
        <CenterImg small url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670752837/HGE%20Course%20Assets/4abc56689789cd727a42b9e8b0e0d5b9_etgcpw.jpg'}/>
        <CenterQuote text={'We use China’s best curriculum (Renmin and Jinan Uni) to take your child’s Chinese to new heights. '}/>
        <FocusSection type={ type }/>
      </>
    )
  case 'advancedChinese':
    return (
      <>
        <CenterImg small url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670768636/HGE%20Course%20Assets/71SU0eG2xIL_xdbls9.jpg'}/>
        <LargeGreenText text={'Our teachers are trained in the latest exam formats.'}/>
        <FocusSection type={ type }/>
        <HorizontalQuoteImg text={'Pass the HSK with just 2 months of practice.'} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670769264/HGE%20Course%20Assets/hanyu-shuiping-kaoshi-2-1024x483_dv0jc1.jpg'}/>
        <div style={{ width:'80%', margin:'auto', textAlign: 'center' }}><a href="/contact"><Button> Contact Us</Button></a></div>
      </>
    )
  case 'singaporeMath':
    return (
      <>
        <HorizontalQuoteImg text={'We offer the worlds’ renowned Singapore Math Syllabus.'} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670822617/HGE%20Course%20Assets/91m60g3ohKL_ltdql1.jpg'}/>
        <HorizontalQuoteImg flipped text={'Stretch your child’s analytical abilities.'} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670822725/HGE%20Course%20Assets/Screenshot_2022-09-25_at_8.54_1_ssp81e.png'}/>
        <Divider />
      </>
    )
  case 'afterschoolProgram':
    return (
      <>
        <HorizontalQuoteImg text={'Can’t pick your child up after school? We got you.'} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670822344/HGE%20Course%20Assets/0f0b2cfb4d43228e8d4ef10476ffdd9a_1_aprllz.png'}/>
        <FocusSection type={ type }/>
      </>
    )
  case 'summerCamp':
    return (
      <>
        <LargeGreenText text={'Summer camp 2023 is now open for registration!'}/>
        <CenterImg url={'https://res.cloudinary.com/dl4murstw/image/upload/v1689826735/Screenshot_2023-07-20_at_12.17.43_PM_fe1on7.png'}/>
        <CenterImg url={'https://res.cloudinary.com/dl4murstw/image/upload/v1689826737/Screenshot_2023-07-20_at_12.18.35_PM_qtagln.png'}/>
        <div style={{ width:'50%', margin:'auto', textAlign: 'center' }}><a href="/contact"><Button> Register Now</Button></a></div>
        <LargeGreenText text={'Back by popular demand every summer.'}/>
        <CenterImg url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670823588/HGE%20Course%20Assets/IMG_9521_1_gnvuaa.png'}/>
        <CenterImg url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670823618/HGE%20Course%20Assets/IMG_E9410_1_gphehe.png'}/>
        <CenterImg url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670823619/HGE%20Course%20Assets/IMG_0583_1_atvqwn.png'}/>
        <CenterImg url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670823619/HGE%20Course%20Assets/IMG_9279_1_r8ucvf.png'}/>
        <CenterQuote text={'A mix of learning and play! '}/>
        <Divider />
        <div style={{ width:'50%', margin:'auto', textAlign: 'center' }}><a href="/contact"><Button> Call us to enquire</Button></a></div>
      </>
    )
  case 'englishFrench':
    return (
      <>
        <HorizontalQuoteImg text={'Rapidly improve your vocabulary, grammar, and writing with our experienced teachers.'} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670824029/HGE%20Course%20Assets/300798360_366959782299280_7802790697985281078_n_dngnul.jpg'}/>
        <HorizontalQuoteImg flipped text={'Structured English and French classes'} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1670824099/HGE%20Course%20Assets/FrenchStudies023_m0ln5r.jpg'}/>
        <Divider />
        <div style={{ width:'50%', margin:'auto', textAlign: 'center' }}><a href="/contact"><Button> Call us to enquire</Button></a></div>
      </>
    )
  case 'japanese':
    return (
      <>
        <HorizontalQuoteImg text={'Japanese Fundamental Course - In-class/Online'} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1674574349/HGE%20Course%20Assets/japanese_phrases_1_gnonjr.jpg'}/>
        <HorizontalQuoteImg flipped text={'Japanese Language Proficiency Test Course'} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1674574464/HGE%20Course%20Assets/jlptlogo_nzbqsa.jpg'}/>
        <HorizontalQuoteImg text={'Japanese Translation Course (Books, Manga, etc)'} url={'https://res.cloudinary.com/dl4murstw/image/upload/v1674574435/HGE%20Course%20Assets/159266_b80ed9497e4b4f1a8ab94d05a7662a3c_mv2_cd7tqy.webp'}/>
        <Divider />
        <div style={{ width:'50%', margin:'auto', textAlign: 'center' }}><a href="https://rosemaryheights333.wixsite.com/white-rock-japanese"><Button> Click here to find out more</Button></a></div>
      </>
    )
  }
}

export default CourseBody