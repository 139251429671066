import React from 'react'
import Logo from '../styled/centerLogo'
import Title from '../styled/centerTitle'
import Audience from '../styled/centerPara'
import Divider from '../styled/divider'
import Description from '../styled/centerPara'

const CourseHeader = ( { logo, title, audience, description } ) => {
  return (
    <>
      <Logo url={logo} />
      <Title text={title} />
      <Audience text={audience} />
      <Divider />
      <Description text={description} />
    </>

  )
}

export default CourseHeader
// , title, audience, description