import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Footer = () => {

  return (
    <>
      <ContactContainer>
        <BackgroundContainer>
          <ContactDetails>
            <Img src="https://res.cloudinary.com/dl4murstw/image/upload/v1664043588/PureLogo-removebg-preview_kcwvs2.png" height="230" width="250"/>
            <Title>Harvard Garden Education</Title>
            <Subtitle>Everyone can learn, love & lead </Subtitle>
            <Line><img style={{ marginRight:'3px' }} src="https://res.cloudinary.com/dl4murstw/image/upload/v1663298199/email_icon_i4seeo.png" width="20"/> harvardcs888@gmail.com</Line>
            <Line><img style={{ marginRight:'3px' }} src="https://res.cloudinary.com/dl4murstw/image/upload/v1663298199/call_icon_fy6enu.png" width="20"/> 778-895-6686</Line>
            <Line>Wechat/微信 ID: wenwangjaja Web: www.harvardge.com</Line>
            <Line><img style={{ marginRight:'3px' }} src="https://res.cloudinary.com/dl4murstw/image/upload/v1663298199/house_icon_yikpwt.png" width="20"/> #140,3388,Rosemary Heights Crescent,Surrey BC.CA.</Line>
          </ContactDetails>
          <ContainerDiv>
            <LeftPane>Harvard Garden Education 2020</LeftPane>
            <RightMenuContainer>
              <MenuBar>
                <MenuItem><Link to="/about">ABOUT US</Link></MenuItem>
                <MenuItem><Link to="/courses">COURSES</Link></MenuItem>
                <MenuItem><Link to="/career">CAREER</Link></MenuItem>
                <MenuItem><Link to="/contact">CONTACT US</Link></MenuItem>
              </MenuBar>
            </RightMenuContainer>
          </ContainerDiv>
        </BackgroundContainer>
      </ContactContainer>


    </>
  )
}

export default Footer

const ContainerDiv = styled.div`
    width: 100%;
    padding-top: 15em;
    padding-bottom: 1em;
    // background-color: #65977C;
    // border: 1px solid blue;
    align-items: center;
    display:flex;
    justify-content:space-between;
`

const LeftPane = styled.div`
    margin-left: 5em;
    // border: 1px solid blue;
    display: inline;
    font-family: "Inter", sans serif;
`
const RightMenuContainer = styled.div`
    display: flex;
    // border: 1px solid red;

`

const MenuBar = styled.div`
    margin-right: 3em;
    display:flex;
    justify-content:space-between;
`

const MenuItem = styled.p`
    margin: 2em;
    font-size:0.9em;
    font-family: 'Inter';
    font-weight: 600;
    a {
        text-decoration: none;
    }
    a:visited {
        color: black;
    }
`

const ContactContainer = styled.div`
    // border: 1px solid red;
    width:100%;
    position: relative;
    overflow: hidden;
`

const BackgroundContainer = styled.div`
    background-image: url("https://res.cloudinary.com/dl4murstw/image/upload/v1663296850/Vector_1_oxwsyo.png");
    background-size: 100%;
    background-repeat: no-repeat;
    z-index:-1;
`

const Title = styled.h1`
    font-family: "Arima Madurai", sans-serif;
    font-size: 3em;
    text-align: right;
    margin-bottom: 0;
`

const Subtitle = styled.div`
    font-family: "Arima Madurai", sans-serif;
    font-size: 2em;
    text-align: right;
    font-weight: bold;
    margin: 0;
`
const ContactDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Line = styled.div`
    font-family: "Be Vietnam Pro", arial;
    margin: 3px;
    display: flex;
`




const Img = styled.img`
    margin-top: 11em;

`