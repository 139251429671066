import React from 'react'
import styled from 'styled-components'

const Img = styled.img`
    display: block;
    margin: auto;
    width: 50%;
    margin-top: 10em;
    margin-bottom: 10em;
    // border: 1px solid red;

`

const CenterImg = ( { url, mt, mb, small } ) => {

  if (small) {
    return (
      <Img src={url} style={{ marginTop: `${mt}em`, marginBottom: `${mb}em`, width:'20%' }}>
      </Img>
    )
  } else {
    return (
      <Img src={url} style={{ marginTop: `${mt}em`, marginBottom: `${mb}em` }}>
      </Img>
    )
  }
}

export default CenterImg