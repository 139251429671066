import React from 'react'
import CenterQuote from '../components/styled/centerQuote'
import Header from '../components/homepage/Header'
import CenterImg from '../components/styled/centerImg'


const TimeTable = () => {

  return (
    <>
      <Header />
      <CenterQuote text={'Sep - Dec 2023 Time Table '}/>
      <CenterImg url={'https://res.cloudinary.com/dl4murstw/image/upload/v1689827104/Screenshot_2023-07-20_at_12.24.54_PM_x2akkv.png'}/>
      <CenterImg url={'https://res.cloudinary.com/dl4murstw/image/upload/v1689827527/Screenshot_2023-07-20_at_12.31.44_PM_arx52y.png'}/>
    </>

  )
}

export default TimeTable