import React from 'react'
import styled from 'styled-components'
import Header from '../components/about/Header'
import Footer from '../components/about/Footer'
import Iframe from 'react-iframe'

const Contact = () => {

  return (
    <>
      <MainContentWrapper>
        <BackgroundBanner>
          <Header />
          <AboutContainer>
            <AboutTitle>Contact Us!</AboutTitle>
            <RowContainer>
              <Description>
                We are based in South Surrey, BC. Give us a call, or text us on Wechat! <br></br><br></br>
                <Line><img style={{ marginRight:'3px' }} src="https://res.cloudinary.com/dl4murstw/image/upload/v1663298199/email_icon_i4seeo.png" width="20"/> harvardcs888@gmail.com</Line><br></br>
                <Line><img style={{ marginRight:'3px' }} src="https://res.cloudinary.com/dl4murstw/image/upload/v1663298199/call_icon_fy6enu.png" width="20"/> 778-895-6686</Line><br></br>
                <Line><img style={{ marginRight:'3px' }} src="https://res.cloudinary.com/dl4murstw/image/upload/v1663298199/house_icon_yikpwt.png" width="20"/> #140,3388,Rosemary Heights Crescent,Surrey BC.CA.</Line><br></br>
                <Line>Wechat/微信 ID: H7788956686</Line><br></br>
              </Description>
              <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2614.2157974421784!2d-122.80017808405128!3d49.06353259419385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485c4b6000f2279%3A0x183abc6d96a95d26!2sHarvard%20Garden%20Education!5e0!3m2!1sen!2ssg!4v1670826432780!5m2!1sen!2ssg"
                width="500px"
                height="250px"
                id=""
                className=""
                display="block"
                position="relative"/>
              <Logo><img src="https://res.cloudinary.com/dl4murstw/image/upload/v1664056054/Group_2_ruvtje.png" height="250" /></Logo>
            </RowContainer>
          </AboutContainer>
          <Footer />
        </BackgroundBanner>
      </MainContentWrapper>

    </>
  )
}

export default Contact

const MainContentWrapper = styled.div`
`

const BackgroundBanner = styled.div`
    background-image: url("https://res.cloudinary.com/dl4murstw/image/upload/v1664055202/Vector_3_iqazut.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    min-height:100vh; 
    display:flex; 
    flex-direction:column; 
    justify-content:space-between;

`
const AboutContainer = styled.div`
    width: 80%;
    margin: auto;
`

const AboutTitle = styled.h1`
    font-family: "Arima Madurai", sans-serif;
    font-size: 3em;
    margin: 0;
    color: #66997E;
    margin-top: 2em;
    margin-bottom: 0;
`

const RowContainer = styled.div`
    display: flex;
    margin-top:2em;
    align-items: center;
    justify-content: space-between;
`

const Description = styled.div`
    font-family: "Arima Madurai", sans-serif;
    font-size: 1em;
    text-align: left;
    margin-right: 0;
    margin-top: 2em;
    // width: 70%;

`
const Logo = styled.div`
    float: right;
    width: 30%;
    margin-top:0;
    position: relative;

`
const Line = styled.div`
    font-family: "Be Vietnam Pro", arial;
    margin: 3px;
    display: flex;
`