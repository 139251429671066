import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Banner = () => {

  return (
    <>
      <BannerContainer>
        <img src={'https://res.cloudinary.com/dl4murstw/image/upload/v1662953106/background_img2_mh6oxf.jpg'} style={{ objectFit: 'contain' }} />
        <VectorContainer>
          <BannerText>
            <LeftPane>
              <Title>Harvard Garden Education</Title>
              <Subtitle>Let&apos;s learn & grow together!</Subtitle>
              <Description>We were established in 2014 by our founder, Mrs Wang Wenxia, to provide<br /> comprehensive & professional educational support for enjoyable and effective <br />Chinese learning.</Description>
              <Link to={'/about'}><LearnMoreButton>Learn More</LearnMoreButton></Link>
            </LeftPane>
            <SocialsPane>
            </SocialsPane>
          </BannerText>
        </VectorContainer>

      </BannerContainer>

    </>

  )
}

export default Banner

const BannerContainer = styled.div`
// border: 1px solid red;
height: 80vh;
// width:100%;
position: relative;
overflow:hidden;
`

// const BackgroundContainer = styled.div`
//     background-image: url("https://res.cloudinary.com/dl4murstw/image/upload/v1662953106/background_img2_mh6oxf.jpg");
//     background-size: 100%;
//     background-repeat: no-repeat;
//     min-height: 100vh;
//     min-width: 100%;
//     z-index:-1;
// `

const VectorContainer = styled.div`
    background-image: url("https://res.cloudinary.com/dl4murstw/image/upload/v1662953154/curve_acoh6n.png");
    // border: 1px solid blue;
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index:1;
    min-height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items:center;

`

const BannerText = styled.div`
    width: 70%;
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 12em;
    // border: 1px solid cyan;
    z-index: 2;
    display:flex;
`

const LeftPane = styled.div`
    // border: 1px solid green;
    width: 90%;
`

const SocialsPane = styled.div`
    // border: 1px solid blue;
    width: 5%;
`

const Title = styled.h1`
    font-family: "Arima Madurai", sans-serif;
    font-size: 4em;
    text-align: right;
    margin: 0;
`

const Subtitle = styled.h1`
    font-family: "Arima Madurai", sans-serif;
    font-size: 2.5em;
    text-align: right;
    margin: 0;
`

const Description = styled.h1`
    font-family: "Arima Madurai", sans-serif;
    font-size: 1em;
    text-align: right;
    margin: 0;
`

const LearnMoreButton = styled.button`
    background: #65977C;
    border-radius: 30px;
    float: right;
    margin-top: 1em;
    border: none;
    color: white;
    width: 11em;
    height: 2.5em;
    font-size: 1em;
    cursor: pointer;
`