import React from 'react'
import styled from 'styled-components'
import Card from './Card.js'

const Courses = () => {

  return (
    <>
      <CoursesContainer>
        <FloatingStarRight><img src="https://res.cloudinary.com/dl4murstw/image/upload/v1663267470/Stars_udy4pn.png" height="300"/></FloatingStarRight>
        <Title>Our Popular Courses</Title>
        <CardRow>
          <Card id={0}/>
          <Card id={1}/>
          <Card id={2}/>

        </CardRow>
        <CardRow>
          <Card id={3}/>
          <Card id={4}/>
          <Card id={5}/>
        </CardRow>
        <FloatingStarLeft><img src="https://res.cloudinary.com/dl4murstw/image/upload/v1663267488/Stars2_u0hyqm.png" height="300"/></FloatingStarLeft>
      </CoursesContainer>


    </>

  )
}

export default Courses

const CoursesContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 5em;
`
const Title = styled.h1`
    font-family: "Arima Madurai", sans-serif;
    font-size: 2.5em;
    text-align: center;
    margin: 3em;
    margin-bottom: 1em;
    // border: 1px solid red;

`

const CardRow = styled.div`
    display: flex;
    justify-content: center
`

const FloatingStarRight = styled.div`
    position: absolute;
    right: 0;
    top: 3em;
    z-index: 1;
`

const FloatingStarLeft = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
`