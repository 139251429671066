import React from 'react'
import styled from 'styled-components'
import Header from '../components/homepage/Header'
import Banner from '../components/homepage/Banner'
import FeaturedTiles from '../components/homepage/FeaturedTiles'
import Courses from '../components/homepage/Courses'
import RecentActivities from '../components/homepage/RecentActivities'
import Footer from '../components/homepage/Footer'

const MainContentWrapper = styled.div`
    min-height:100vh; 
    display:flex; 
    flex-direction:column; 
`
const HomePage = () => {

  return (
    <>
      <MainContentWrapper>
        <Header />
        <Banner />
        <FeaturedTiles />
        <Courses />
        <RecentActivities />
        <Footer />
      </MainContentWrapper>

    </>
  )
}

export default HomePage

// <Banner />
// <FeaturedTiles />
// <PopularCourses />
// <RecentActivities />
// <ContactDetails />
// <Footer />
