import React from 'react'
import styled from 'styled-components'
import Header from '../components/about/Header'
import Footer from '../components/about/Footer'
import { Card } from 'antd'

const gridStyle = {
  width: '25%',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}

const About = () => {

  return (
    <>
      <MainContentWrapper>
        <BackgroundBanner>
          <Header />
          <AboutContainer>
            <AboutTitle>About Us</AboutTitle>
            <RowContainer>
              <Description>Harvard Garden Education is South Surrey&apos;s best Chinese tuition center. Started in 2014 by
                        our principal Mrs Wang, we believe that
                        anyone can master Chinese as long as the right guidance is given. Mrs Wang has 25 years of teaching experience (12 years in Singapore and 12 years in Canada).

              <br /><br />Our approach in teaching is characterized by explanations supplemented in both English & Chinese,
                        so as to better understand the cross-culture linguistic differences between two of the most widely used languages in the world.

              <br /><br /> Throughout the years, we have also developed best-in-class Math, English, Arts, and After School Programs.
              </Description>
              <Logo><img src="https://res.cloudinary.com/dl4murstw/image/upload/v1664056054/Group_2_ruvtje.png" height="250" /></Logo>
            </RowContainer>
          </AboutContainer>

          <TeachersContainer>
            <TeachersTitle>Meet our teachers</TeachersTitle>
            <Card title="">
              <Card.Grid hoverable={false} style={gridStyle}>
                <img src="https://res.cloudinary.com/dl4murstw/image/upload/v1671260791/photo1671260717_1_lgtmcy.jpg" height="300" width="300"/>
                <h2>Mrs Wang</h2>
                <p>Principal and Chinese Tutor</p>
              </Card.Grid>
              <Card.Grid hoverable={false} style={gridStyle}>
                <img src="https://res.cloudinary.com/dl4murstw/image/upload/v1671260649/mmexport1671260083570_uhlhnu.jpg" height="300" width="300"/>
                <h2>Ms Sophia</h2>
                <p>Lead Chinese Tutor</p>
              </Card.Grid>
              <Card.Grid hoverable={false} style={gridStyle}>
                <img src="https://res.cloudinary.com/dl4murstw/image/upload/v1671260649/mmexport1671260071699_engv4s.jpg" height="300" width="300"/>
                <h2>Ms Su</h2>
                <p>Chinese Tutor</p>
              </Card.Grid>
              <Card.Grid hoverable={false} style={gridStyle}>
                <img src="https://res.cloudinary.com/dl4murstw/image/upload/v1671260649/mmexport1671260095698_ffzdfb.jpg" height="300" width="300"/>
                <h2>Ms Grace</h2>
                <p>English and French Tutor</p>
              </Card.Grid>
              <Card.Grid hoverable={false} style={gridStyle}>
                <img src="https://res.cloudinary.com/dl4murstw/image/upload/v1671260649/mmexport1671260102470_mlllgz.jpg" height="300" width="300"/>
                <h2>Ms Lu</h2>
                <p>French, Chinese, and Art Tutor</p>
              </Card.Grid>
            </Card>
          </TeachersContainer>
          <Footer />
        </BackgroundBanner>
      </MainContentWrapper>

    </>
  )
}

export default About

{/* <TeachersRow>
              <img src="https://res.cloudinary.com/dl4murstw/image/upload/v1671260791/photo1671260717_1_lgtmcy.jpg" height="300" width="300"/>
              <TeacherName>Mrs Wang</TeacherName>
              <img src="https://res.cloudinary.com/dl4murstw/image/upload/v1671260649/mmexport1671260083570_uhlhnu.jpg" height="300" width="300"/>
              <TeacherName>Ms Sophia</TeacherName>
              <img src="https://res.cloudinary.com/dl4murstw/image/upload/v1671260649/mmexport1671260071699_engv4s.jpg" height="300" width="300"/>
              <TeacherName>Ms Su</TeacherName>
            </TeachersRow>
            <TeachersRow>
              <img src="https://res.cloudinary.com/dl4murstw/image/upload/v1671260649/mmexport1671260095698_ffzdfb.jpg" height="300" width="300"/>
              <TeacherName>Ms Grace</TeacherName>
              <img src="https://res.cloudinary.com/dl4murstw/image/upload/v1671260649/mmexport1671260102470_mlllgz.jpg" height="300" width="300"/>
              <TeacherName>Ms Lu</TeacherName>
            </TeachersRow> */}

const MainContentWrapper = styled.div`
`

const BackgroundBanner = styled.div`
    background-image: url("https://res.cloudinary.com/dl4murstw/image/upload/v1664055202/Vector_3_iqazut.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    min-height:100vh; 
    display:flex; 
    flex-direction:column; 
    justify-content:space-between;

`
const AboutContainer = styled.div`
    width: 80%;
    margin: auto;
`

const AboutTitle = styled.h1`
    font-family: "Arima Madurai", sans-serif;
    font-size: 3em;
    margin: 0;
    color: #66997E;
    margin-top: 2em;
    margin-bottom: 0;
`

const RowContainer = styled.div`
    display: flex;
`

const Description = styled.div`
    font-family: "Arima Madurai", sans-serif;
    font-size: 1em;
    text-align: left;
    margin-right: 0;
    margin-top: 2em;
    // width: 70%;

`
const Logo = styled.div`
    float: right;
    width: 30%;
    margin-top:0;
    position: relative;

`

const TeachersContainer = styled.div`
    font-family: "Arima Madurai", sans-serif;
    font-size: 2em;
    color: #66997E;
    width: 80%;
    margin: auto;
    padding-bottom: 3em;

`

const TeachersTitle = styled.div`
    margin-top: 2em;
    margin-bottom: 2em;
`


