export const cardData = [
  {
    id: 0,
    title: 'Chinese Is Fun!',
    subtitle: '(5-8 years old)',
    border: '1px solid #E58D6F',
    backgroundUrl: 'url(\'https://res.cloudinary.com/dl4murstw/image/upload/v1663265728/green_gd3slh.png\')',
    elaborationText: 'For absolute beginners; focusing on basic conversations and recognizing words through nursery rhymes, songs, and craft.',
    hoverBackgroundColor: '#F9E696',
    url: '/courses/chinese-is-fun'
  },
  {
    id: 1,
    title: 'Fundamental Chinese',
    subtitle: '(6-12 years old)',
    border: '1px solid #38E769',
    backgroundUrl: 'url(\'https://res.cloudinary.com/dl4murstw/image/upload/v1663265728/purple_hvhfxb.png\')',
    elaborationText: 'Daily conversational Chinese, expansion of vocabulary, Hanyupinyin, and igniting students\' love for Chinese.',
    hoverBackgroundColor: '#CE95C5',
    url: '/courses/fundamental-chinese'
  },
  {
    id: 2,
    title: 'Intermediate Chinese',
    subtitle: '(8-14 years old)',
    border: '1px solid #A27FDB',
    backgroundUrl: 'url(\'https://res.cloudinary.com/dl4murstw/image/upload/v1663265728/blue_jlrgr4.png\')',
    elaborationText: 'Extensive reading, writing, vocabulary building using China\'s official school syllabus (Renmin and Jinan Uni Curriculum). Emphasis on exposure to Chinese culture, analytical thinking, and character development.',
    hoverBackgroundColor: '#C3F9F9',
    url: '/courses/intermediate-chinese'
  },
  {
    id: 3,
    title: '☀️Summer Camp☀️',
    subtitle: '(6-13 years old)',
    border: '1px solid rgba(149, 151, 206, 0.8)',
    backgroundUrl: 'url(\'https://res.cloudinary.com/dl4murstw/image/upload/v1663265728/pink_ccz6jm.png\')',
    elaborationText: '2023 Summer Camp is now open for registration! Engage your kid in a wide variety of activities this summer! We have the most fun and enriching summer camps in Surrey.',
    hoverBackgroundColor: '#CE95C5',
    url: '/courses/summer-camp'
  },
  {
    id: 4,
    title: 'Singapore Mathematics',
    subtitle: '(7 - 17 years old)',
    border: '1px solid #E4BE37',
    backgroundUrl: 'url(\'https://res.cloudinary.com/dl4murstw/image/upload/v1663265728/yellow_pn0qmb.png\')',
    elaborationText: 'Uses world renowned Singapore syllabus to further your child\'s mathematical foundations.',
    hoverBackgroundColor: '#E5AE1E',
    url: '/courses/singapore-math'
  },
  {
    id: 5,
    title: 'After School Program',
    subtitle: '(5-14 years old)',
    border: '1px solid #E51EB9',
    backgroundUrl: 'url(\'https://res.cloudinary.com/dl4murstw/image/upload/v1663265728/orange_a6zjpq.png\')',
    elaborationText: 'Turn aimless hours after school into productive learning time. Can’t pick your kids up after school? We got you.',
    hoverBackgroundColor: '#E28E71',
    url: '/courses/afterschool-program'
  },
  {
    id: 6,
    title: 'Advanced Chinese',
    subtitle: '(14-18 years old)',
    border: '1px solid #E58D6F',
    backgroundUrl: 'url(\'https://res.cloudinary.com/dl4murstw/image/upload/v1663265728/green_gd3slh.png\')',
    elaborationText: 'An advanced class for students who wants to take the IB/AP / HSK.  Perfect for those who needs help for exam training and writing pratices.',
    hoverBackgroundColor: '#E58D6F',
    url: '/courses/advanced-chinese'
  },
  {
    id: 7,
    title: 'English/French lessons',
    subtitle: '(6-12 years old)',
    border: '1px solid #38E769',
    backgroundUrl: 'url(\'https://res.cloudinary.com/dl4murstw/image/upload/v1663265728/purple_hvhfxb.png\')',
    elaborationText: 'Brush up on your English and French skills!',
    hoverBackgroundColor: '#CE95C5',
    url: '/courses/english-french'
  },
  {
    id: 8,
    title: 'Japanese Class',
    subtitle: '(All ages)',
    border: '1px solid #A27FDB',
    backgroundUrl: 'url(\'https://res.cloudinary.com/dl4murstw/image/upload/v1663265728/blue_jlrgr4.png\')',
    elaborationText: 'We provide individualized instruction for students who are new to the Japanese language and for those who want to improve it.',
    hoverBackgroundColor: '#CE95C5',
    url: '/courses/japanese'
  }
]

