import React from 'react'
import CenterQuote from '../components/styled/centerQuote'
import Header from '../components/homepage/Header'


const ComingSoon = () => {

  return (
    <>
      <Header />
      <CenterQuote text={'Coming Soon!'}/>
    </>

  )
}

export default ComingSoon