import styled from 'styled-components'
import React from 'react'

const Text = styled.div`
    font-family: 'Arima Madurai';
    font-weight: 600;
    margin: auto;
    margin-top: 3em;
    margin-bottom: 3em;
    width: 50%;
    text-align: center;
    font-size: 3em;
    line-height: 56px;
    color: #428071;
`

const LargeGreenText = ( { text, mt, mb } ) => {
  return (
    <Text style={{ marginTop: `${mt}em`, marginBottom: `${mb}em` }}> { text }</Text>
  )
}

export default LargeGreenText