import React from 'react'
import styled from 'styled-components'

const CenterTitle = styled.div`
    font-family: "Arima Madurai", sans-serif;
    font-size: 3.5em;
    font-weight: bold;
    text-align: center;
    margin-right: 0;
    margin-top: 1.5em;
    margin-bottom: 1em;
`

const Title = ({ text }) => {
  return (
    <CenterTitle>{text}</CenterTitle>
  )
}

export default Title