import React from 'react'
import styled from 'styled-components'


const QuoteContainer = styled.div`
    width: 650px;
    margin: auto;
`

const CenterPara = styled.div`
    font-family: "Arima Madurai", sans-serif;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 2em;
    font-weight: 600;
    text-align: center;
`

const Para = ( { text, mt, mb, underline } ) => {
  return (
    <QuoteContainer>
      {underline ? <CenterPara style={{ marginTop: `${mt}em`, marginBottom: `${mb}em`, textDecoration: 'underline' }}>{ text }</CenterPara> : <CenterPara style={{ marginTop: `${mt}em`, marginBottom: `${mb}em` }}>{ text }</CenterPara>}
    </QuoteContainer>


  )
}

export default Para