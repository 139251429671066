import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { cardData } from './cardHelper'
import { Button } from '../styled/Button'
import { Link } from 'react-router-dom'

const Card = ({ id }) => {
  const [flipped, setFlipped] = useState(false)
  console.log(id)
  console.log(cardData[id])
  return(
    <Container onMouseEnter={() => setFlipped(true)} onMouseLeave={() => setFlipped(false)}>
      {flipped
        ? (
          <HoverCard style={{ backgroundColor:cardData[id].hoverBackgroundColor }}>
            <ElaborationText>{cardData[id].elaborationText}</ElaborationText>
            <Link to={cardData[id].url} style={{ width: '50%', textAlign: 'center' }}><Button>Learn More</Button></Link>
          </HoverCard>
        )
        : (
          <OpenCard style={{ border: cardData[id].border, backgroundImage: cardData[id].backgroundUrl }}>
            <CardTitle>{cardData[id].title}</CardTitle>
            <CardSubtitle>{cardData[id].subtitle}</CardSubtitle>
          </OpenCard>
        )

      }
    </Container>
  )
}

export default Card

const Container = styled.div`
    height: 18em;
    width: 18em;
    margin: 2em;
`
const OpenCard = styled.div`
    height: 100%;
    width: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    background-size: cover;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
`

const HoverCard = styled.div`
    height: 100%;
    width: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    background-size: cover;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
`

const CardTitle = styled.h3`
    font-family: "Be Vietnam Pro", arial;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1em;
`

const CardSubtitle = styled.h3`
    font-family: "Be Vietnam Pro", arial;
    font-size: 0.8em;
    font-style: italic;
    margin-top: 0;
`

const ElaborationText = styled.p`
    font-family: "Be Vietnam Pro", arial;
    font-size: 0.8em;
    text-align: center;
    // margin-bottom: 2em;
    padding: 2em;

`
