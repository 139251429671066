import styled from 'styled-components'
import React from 'react'

const HorizontalLine = styled.hr`
    width: 80%;
    margin: auto;
    margin-top: 5em;
    margin-bottom: 5em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

const Divider = ( { mt, mb }) => {

  return (
    <HorizontalLine style={{ marginTop: `${mt}em`, marginBottom: `${mb}em` }}></HorizontalLine>
  )
}

export default Divider