import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'antd'
import Card from '../homepage/Card'
import { cardData } from '../homepage/cardHelper'



const CourseTiles = () => {
  return (

    <CourseContainer>
      <CourseTitle>Our Courses</CourseTitle>
      <Row gutter={[32, 32]} style={courseContainerStyle}>
        {cardData.map((card, i) => (
          <Col span={6} key={card.title} >
            <Card id={i} key={i}/>
          </Col>
        ))}
      </Row>
    </CourseContainer>

  )
}

export default CourseTiles



const courseContainerStyle = {
  marginTop:'5em',
  marginBottom:'3em'
}


const CourseContainer = styled.div`
    width: 80%;
    margin: auto;
`

const CourseTitle = styled.h1`
    font-family: "Arima Madurai", sans-serif;
    font-size: 3.5em;
    font-weight: 550;
    text-align: center;
    color: #66997E;
    margin-top: 5em;
    margin-bottom: 0;
`

