import React from 'react'
import styled from 'styled-components'
import CenterImg from './centerImg'
import LargeGreenText from './largeGreenText'

const OuterContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 60%;
    margin: auto;
    align-items: center;
`


const HorizontalQuoteImg = ( { text, url, flipped } ) => {

  if (flipped) {

    return (
      <OuterContainer>
        <CenterImg url={url}/>
        <LargeGreenText text={text}/>
      </OuterContainer>
    )

  } else {
    return (
      <OuterContainer>
        <LargeGreenText text={text}/>
        <CenterImg url={url}/>
      </OuterContainer>
    )
  }
}

export default HorizontalQuoteImg