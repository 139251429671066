import React from 'react'
import styled from 'styled-components'

const CenterNormalText = styled.div`
    font-family: "Arima Madurai", sans-serif;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    margin-top: 2.5em;
    margin-bottom: 1.5em;
`

const CenterText = ( { text } ) => {
  return (
    <CenterNormalText>{ text }</CenterNormalText>
  )
}

export default CenterText