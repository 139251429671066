import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'


const OuterContainer = styled.div`
    // border: 1px solid blue;
    width: 100%;
    height: 22em;
    display: flex;
`

const Tile = styled.div`
    // border: 1px solid green;
    width: 25%;
    display: flex;
    flex-direction: column;
    text-align: center;
`

const Icon = styled.div`
    margin-top: 2em;
`

const Title = styled.h3`
    font-family: "Be Vietnam Pro", arial;
    font-weight: 700;
`

const Description = styled.h3`
    font-family: "Be Vietnam Pro", arial;
    font-weight: 400;
    // border: 1px solid green;
    width: 70%;
    margin: auto;
    margin-top:0;
    font-size: 1em;
    text-align: left;
`

const LearnMoreButton = styled.button`
    border: 1px solid #000000;
    border-radius: 30px;
    width: 70%;
    margin: auto;
    margin-top: 0;
    background-color: transparent;
    height: 3em;
    font-size: 0.8em;
    font-family: "Inter", sans-serif;
    font-weight: bold;
    color: black;
    :hover {
        background: #D4DB7D;
        border-radius: 30px;
        border: none;
        color: #FFFFFF;
        cursor: pointer;
    }
`
const FeaturedTiles = () => {

  return (
    <>
      <OuterContainer>
        <Tile style={{ backgroundColor: '#78C294' }}>
          <Icon><img src="https://res.cloudinary.com/dl4murstw/image/upload/v1663213051/bookicon_v4w0so.png" width="55" /></Icon>
          <Title>Chinese Language Classes</Title>
          <Description>We provide quality Chinese language classes for all levels, whether you’re a complete beginner or you’re trying to master the fundamentals of the language.</Description>
        </Tile>

        <Tile style={{ backgroundColor: '#7FC8DB' }}>
          <Icon><img src="https://res.cloudinary.com/dl4murstw/image/upload/v1663213051/bus_1_mjgula.png" width="55" /></Icon>
          <Title>After School Programs</Title>
          <Description>Turn aimless hours after school into productive learning time. Can’t pick your kids up after school? <br /> <br />We got you.</Description>
        </Tile>

        <Tile style={{ backgroundColor: '#9383BA' }}>
          <Icon><img src="https://res.cloudinary.com/dl4murstw/image/upload/v1663213051/cert_zky81u.png" width="55" /></Icon>
          <Title>Qualified Teachers</Title>
          <Description>Our teachers have a combined teaching experience of over 25 years.</Description>

        </Tile>
        <Tile style={{ backgroundColor: '#E28E71' }}>
          <Title style={{ marginTop:'5.8em', width: '70%', margin: 'auto', textAlign: 'left', marginBottom: '1.5em' }}>Providing quality <br />education in a conducive<br />environment.</Title>
          <Link to={'/about'} style={{ marginBottom: '5em' }} ><LearnMoreButton>Learn More</LearnMoreButton></Link>
        </Tile>
      </OuterContainer>
    </>

  )
}

export default FeaturedTiles
